import { useState,useEffect } from 'react';
import  logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import '../css/navbar.css';
import Package from './package';
import ContactModal from './contactModal';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import menueIcon from '../assets/menuIcon.png'
import closeIcon from '../assets/closeIcon.png'
 const Navbar = () =>{
    const [active, setActive] = useState(false);
    let [open,setOpen] = useState(false);
    const [menu,setMenu] = useState(false);
    let location = useLocation();

    let[t,i18n]  = useTranslation();



    useEffect(() => {
      if(localStorage.getItem('language')!== ""){
        handleChange(localStorage.getItem('language'))
      }
      function handleScroll() {
        if(window.scrollY>80){
            setActive(true)
        }
        else{
            setActive(false)
        }
      }
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    function handleMenuClick(){
          setMenu(!menu)
    }

    const handleChange =(lang)=>{
      localStorage.setItem('language',lang);
          i18n.changeLanguage(lang);
          
    }

    return(
        <nav>
           <div>
            <Link className='navigate' to = "/">
              <img className='tag' src = {logo} alt = "tag" />
              </Link>
            </div>
            <div className='contactDetails'>

            <div >
              <p>Email us</p>
              <p className='bold'> travellersjunglecamp@gmail.com</p>
            </div>
            <div>
              <p>Contact us</p>
     
          <p onClick={makecall}  style = {{cursor : "pointer"}}  className='bold'>056-580013 </p> 
            </div>
            </div>
          <ContactModal open = {open} setOpen = {()=>setOpen(false)}/>


        </nav>
    )
}

function makecall(){
  window.location.href = "tel:+977056580013";
}

const StickyBar = ()=>{

  const [active, setActive] = useState(false);
  let [open,setOpen] = useState(false);
  const [menu,setMenu] = useState(false);
  let location = useLocation();

  let[t,i18n]  = useTranslation();

  function handleMenuClick(){
    setMenu(!menu)
}

const handleChange =(lang)=>{
localStorage.setItem('language',lang);
    i18n.changeLanguage(lang);
    
}

function makePhoneCall() {
  // Replace the phone number with your desired phone number
  window.location.href = "tel:+9779845025845";
}

  return(
    <div className={`navigation`}>

    {/* <div>
    <Link className='navigate' to = "/">
      <img className='tag' src = {logo} alt = "tag" />
      </Link>
    </div> */}
   
   <div className='navOptions'>
    <div className='navInnerDiv'>
   
   {/* <div className='phone_number'  >
   

     
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill phone" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
      </svg>
      &nbsp;
      <p>061-123456 </p> 
      </div> */}
      <Link to = '/' className={`links  ${location.pathname == '/' ? 'act' : "" }` }>{t('header.navigation.home')} </Link>
      <p className={`links contact_link ${location.pathname == '/contact-us' ? 'act' : "" }` } onClick={()=>makePhoneCall()} >{t('header.navigation.contact')}</p>
      <Link to = '/photo-gallery' className={`links gallery_link ${location.pathname == '/photo-gallery' ? 'act' : "" }` }>{t('header.navigation.gallery')} </Link>
      <div className='all_packages' >
      <p className='package_link' style={{color: "white"}} >{t('header.navigation.package')}
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
      </svg>
      </p>
       <div className={`package ${active ? "in" : ""}`} >
        <div>
        <Link rel="nofollow"  className='package_link' to="/1night-2days" >1 nights 2 day</Link>

        </div>
        <div>
        <Link rel="nofollow"  className='package_link' to="/2night-3days" >2 nights 3 day</Link>

        </div>
        <div>
        <Link rel="nofollow"  className='package_link' to="/3night-4days" >3 nights 4 day</Link>

        </div>
      
   
      </div>


      </div>

   

      <div className='all_rooms'>
      <p className='rooms_link' style={{color:"white"}} >{t('header.navigation.rooms')}
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  class="bi bi-caret-down-fill" viewBox="0 0 16 16">
              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
      </svg>
      </p>
      <div className='rooms_link_container' >
        <div>
        <Link rel="nofollow"  className='rooms_link' to="/room/standard-tripple-room" >Standard tripple room</Link>

        </div>
        <p></p>
        <div>
        <Link rel="nofollow"  className='rooms_link ' to="/room/deluxe-room" >Deluxe room</Link>

        </div>
        <div>
        <Link rel="nofollow" className='rooms_link' to="/room/cottage-room" >Cottage room</Link>

        </div>
        <div>
        <Link rel="nofollow"  className='rooms_link' to="/room/standard-double-room" >Standard double room</Link>
        </div>
      
   
      </div>
      </div>


      </div>

     
{/* 
      <svg onClick={handleMenuClick} style={{'cursor':'pointer'}} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"   className={`bi ${!menu ? 'bi-list' : 'bi-x'} `} viewBox="0 0 16 16">
             {
              !menu ?
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>

              :
             
           <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
             }
      </svg> */}

      

   
   </div>

  
    {/* <div className='menu' style={{'right' : menu ? '0' : '-250px'}} >
    <div className='c' >
    <Link rel="nofollow" target='_blank' className='navigate' to = "/facilities">Contact</Link> 
    </div>
    <div className='g' >
    <Link rel="nofollow" target='_blank' className='navigate' to = "/facilities">Gallery</Link> 
    </div>
    <div className='f' >
    <Link rel="nofollow" target='_blank' className='navigate' to = "/facilities">Facilities</Link> 
    </div>
    <div className='a' >
    <Link rel="nofollow" target='_blank' className='navigate' to = "/accomodation">Accomodation</Link>
    </div>
    <div className='t'>
    <Link rel="nofollow" target='_blank' className='navigate' to = "/tariff">Tariff</Link>
    </div>
    <div className='p'>
    <Link rel="nofollow" target='_blank' className='navigate' to = "/photo-gallery">Photo gallery</Link>
    </div>
    <div className='fa'>
    <Link rel="nofollow" target='_blank'  className='navigate' to = "/faqs">Faqs</Link>
    </div>
    
    

    </div> */}
      <select style={{color: "white"}} name="translation" onChange={(e)=>handleChange(e.target.value)}>
                 <option value="en"  >English</option>
                 <option value="es">Spanish</option>
                 <option value="chi">Chinese</option>
      </select>
    
    <div className = "menuBar" >
      <img onClick={()=>setMenu(!menu)}  style={{width : "25px",height :"25px",cursor:"pointer"}} src = { menu ? closeIcon :  menueIcon} alt = "menue Icon"/>
      {
        menu && 
        (
          <div className='menu' onClick={()=>setMenu(false)} >
            <div>
            <h2  style = {{marginBottom : "10px"}} >Travellers Jungle camp</h2>
          
            <div style={{ margin : "auto", 'width':'10rem'}} className='horizontal-line' ></div>
            </div>

            <Link className='menuLinks'  to = '/photo-gallery'>Gallery</Link>
            <a className='menuLinks' href = '/#package'>Package</a>
            <a className='menuLinks' href = '/#room'>Room</a>
            <p>Contact Us : 9812345678</p>
          </div>
        )
      }
    </div>

 </div>

  )
}

export default Navbar;
export{StickyBar}