import { useState } from "react";
import a2016 from '../assets/2016.jpeg';
import a2017 from '../assets/2017.jpeg';
import a2018 from '../assets/2018.jpeg';
import a2015 from '../assets/2015.png'
import c1 from '../assets/C1.png';
import c2 from '../assets/c2.png';
import c3 from '../assets/c3.png';
import trip from '../assets/tripAdvisor.svg'


export default function Reviews({t}){
  

    function handleBooking(){
        const newTab = window.open('', '_blank');
        newTab.location.href = 'https://www.booking.com/hotel/np/travellers-jungle-camp.en-gb.html?ucfs=1&hpos=1&sid=e2b53457ab56e859ec274259e2e5cfda&group_adults=2&aid=311984&label=travellers-jungle-camp-p19t4CeKfDF2gaRWS5QYyAS589037066871%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atikwd-15502650698%3Alp9070016%3Ali%3Adec%3Adm%3Appccp%3DUmFuZG9tSVYkc2RlIyh9YXORK0YJiVoOxcWODxYDaAA&no_rooms=1&group_children=0&type=total&dest_type=city&room1=A%2CA&req_children=0&activeTab=main&srepoch=1682606154&dest_id=900049092&req_adults=2&sb_price_type=total&dist=0&hapos=1&srpvid=506066a466270075&sr_order=popularity#tab-main';
    }

    function handleTripAdvisor(){
        const newTab = window.open('', '_blank');
        newTab.location.href = 'https://www.tripadvisor.com/Hotel_Review-g1367591-d1061778-Reviews-Travellers_Jungle_Camp-Sauraha_Chitwan_District_Narayani_Zone_Central_Region.html'
    }

    return(
        <div className="reviews">
     

           
             <div>
            <div className="reviewContainer" >
            <h3  >Booking.com<br/>
            <span className="rating" >9.3</span></h3><br/>
            
                <img src = {a2016} alt = '2016'/>
                <img src = {a2017} alt = '2016'/>

                <img src = {a2018} alt = '2016'/>


            </div>
            <br/>
            <br/>
            <br/>
            <div className="review-book-container">
            <h2>{t('home.review.title')}</h2><br/>
            <p>{t('home.review.p')}</p>
            <br/>
            <div style={{
                'padding':'10px 30px',
                'backgroundColor' : '#1e1925',
                'margin' :'auto',
                'color':'white',
                'display':'inline-block',
                'borderRadius' : '4px',
                'cursor':'pointer'
            }} >
                {t('home.book.button1')}
            </div>
            </div>

        
            </div>
        </div>
    )
}