import '../../css/package.css';
import groupPhoto from '../../assets/groupPhoto.jpeg'
import { useState } from 'react';
import Modal from '../../components/modal';
import { usePackage } from '../../context/packageProvider';

export default function Package2(){
let [open,setOpen] = useState(false);
let {changePackage,packageName} = usePackage();
console.log(packageName)

function handleClick(){
    changePackage("2 night 3 days")
    setOpen(true);
}

    return(
        <div className="package1" >
           <div className='day1'>
             <div className='day1_img'>
             <img src = {groupPhoto} loading='lazy' alt = "sample"/>
             <div>
                <button className='package_book_btn' onClick={handleClick} >Book now</button>
             </div>
             </div>
             <div className='day1_desc' > 
               <h3>2 night 3 days package</h3>
               <br/>
               <p>DAY 1</p>
               <br/>
               <ul>
                <li>[13:00] - Travelling day to chitwan. Free peakup from bus stop and paid pickup from the airport.</li>
                <li>[14:00] - Lunch at the hotel resturant.</li>
                <li>[15:00] - Trip discussion at T.J.C(Complementary service)</li>
                <li>[16:00] - Short sight-seeing around the park area and village tour to nearby ethnic Tharu village where you will have chance to learn about tharu culture and lifestyle.</li>
                <li>[20:00] - Tharu Cultural Dance presentation where you can also praticipate together with the local villagers.</li>
                <li>[21:00] - Dinner</li>
               </ul>

               <br/>
               <p>DAY 2</p>
               <br/>
               <ul>
                <li>[6:00] - Wakeup call</li>
                <li>[6:30] - Breakfast</li>
                <li>[7:00 - 11:00] - 45 minutes of canoe trip followed by 3 hours of Jungle Walk in the forest and elephant breeding center (An excellent opportunity for bird watching and for seeing 2 rare species of crocodile and explore the flora and fauna found in the national park) </li>
                <li>[11:30] - Lunch at the hotel.</li>
                <li>[13:00-17:00] - Half Day Jeep safari inside the national park. (Combined) Note --(Private safari can be arranged)( An excellent opportunity to see four different kinds of deer, rhinoceros, wild boar, monkey, leopard, sloth bear and the Royal Bengal Tiger (If you are lucky).You will also encounter many other smaller mammals that have made Chitwan their home.)</li>
                <li>[20:00] - Dinner</li>
               </ul>

               <br/>
               <p>DAY 3</p>
               <ul>
               <li>[6:00] - Wakeup call</li>
                <li>[6:30] - Breakfast</li>
                <li>[7:15] - Departure for onward journey</li>
               </ul>

             </div>

              
           </div>
           <Modal open={open} close = {()=>setOpen(false)} />
        </div>
    )
}