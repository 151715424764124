
import { useTranslation } from 'react-i18next';


const OverView = ()=>{

    const {t} = useTranslation();

    return(
        <div className = "overview_div">
            <h3 className="overview_header" >{t('overview.title')}</h3>
            <div style={{'margin':'auto','width':'6rem'}} className='horizontal-line' ></div>
            <div className="overview_p" >
                <p>{t('overview.phase1')}
<br/>
<br/>
{t('overview.phase2')}
<br/>
<br/>
{t('overview.phase3')}
<br/>
<br/>

{t('overview.phase4')}
</p>
            </div>
        </div>
    )
}

export default OverView