import '../../css/package.css';
import selfie from '../../assets/manSelfieWithJeep.jpeg'
import { useState } from 'react';
import Modal from '../../components/modal';
import { usePackage } from '../../context/packageProvider';


export default function Package1(){
let [open,setOpen] = useState(false);
let {changePackage} = usePackage(); 

function handleClick(){
    changePackage('1 night 2 days')
    setOpen(true);
}

    return(
        <div className="package1" >
           <div className='day1'>
             <div className='day1_img'>
              <div className='fixed_div' >
             <img src = {selfie} loading='lazy' alt = "sample"/>
             <div>
                <button className='package_book_btn' onClick={handleClick} >Book now</button>
             </div>
             </div>
             </div>
             <div className='day1_desc' > 
               <h3>1 night 2 days package</h3>
               <br/>
               <p>DAY 1</p>
               <br/>
               <ul>
                <li>[12:30] - Travelling day to chitwan. Free peakup from bus stop and paid pickup from the airport.</li>
                <li>[13:00] - Lunch</li>
                <li>[14:30] - Trip discussion at T.J.C(Complementary service)</li>
                <li>[15:00] - 2 hours jeep safari in the Buffer zone area.(combined).Note-(Private safari can be arranged)</li>
                <li>[20:00] - Tharu Cultural Dance presentation where you can also praticipate together with the local villagers.</li>
                <li>[21:00] - Dinner</li>
               </ul>

               <br/>
               <p>DAY 2</p>
               <br/>
               <ul>
                <li>[6:00] - Wakeup call</li>
                <li>[6:30] - Breakfast</li>
                <li>[7:15] - Departure for onward journey</li>
               </ul>
             </div>

              
           </div>
           <Modal open={open} close = {()=>setOpen(false)} />
        </div>
    )
}